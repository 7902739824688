/**
 * Blood Instructions page
 * 
 * @returns HairInstructionsPage : PureComponent
 */

import './BloodInstructionsPage.scss';
import { NavLink } from 'react-router-dom';
import { BsArrowLeftSquareFill } from "react-icons/bs";

const BloodInstructionsPage = () => {


    return(
        <section>
            <NavLink to="/" className="blood-form-link"><BsArrowLeftSquareFill/>Go Back</NavLink>
            <section className="home-page">
            
            <h1 className="home-page__header" style={{ fontSize: "1.6rem" }}><b>Blood Instructions</b></h1>
            <div className="thank-you">

            <div className="thank-you__subtitle">
                <p>Please follow the activation instructions and activate your test here.
                Please fill in Black ink and in BLOCK CAPITALS and return with your blood sample.</p>
            </div>
            <br />
            <p><b>Step 1 |</b> Visit activatemytest.com and fill in the online form as well as the blood submission form in your kit.
            <br />
            <small>You will need to return this form with your Blood submission. Failure to activate will mean we cannot process your sample.</small></p>
            <br />
            <p><b>Step 2 |</b> Open the blood spot card. Fold the paper back to reveal the collection paper. Do not touch sample area.</p>
            <br />
            <p><b>Step 3 |</b> Hold your hand under warm water for at least one minute to stimulate blood circulation then carefully dry your hand with towel.</p>
            <br />
            <p><b>Step 4 |</b> Stand up and swing your arm around in a circular motion 5-10 times, with your arm fully extended if possible. This helps stimulate blood circulation. Continue standing until you've finished taking your sample, if possible.</p>
            <br />
            <p><b>Step 5 |</b>Remove the provided disinfectant cloth from its film and use it to disinfect the entire tip of your index or middle finger. Wait approximately 15 seconds for your finger to dry.
            <br />
            <small>TIP: your middle finger is usually the most suitable.</small></p>
            <br />
            <p><b>Step 6 |</b>Rotate the blue protective cover one full turn then carefully remove it.</p>
            <br />
            <p><b>Step 7 |</b>Push the lancet down firmly so that the blue part of the lancet touches your finger to activate the release mechanism.</p>
            <br />
            <p><b>Step 8 |</b>Keep your hand horizontal at hip height (while standing at the edge of a table, for example) and massage your finger from the palm of the hand to the fingertip until blood droplets form.</p>
            <br />
            <p><b>Step 9 |</b>Hold the base of the collection card to prevent it from moving. Allow blood to drip onto each of the circles, filling each one fully.</p>
            <br />
            <p><b>Step 10 |</b>Once you have filled the six circles, slightly bend the collection card away from the cover to allow air to flow beneath the blood spot collection card. Leave on a flat surface to dry
            <br />
            <small>Please allow 5-10 minutes for your sample to try.</small></p>
            <br />
            <p><b>Step 11 |</b>After collection your blood sample, press the swab firmly against the location of the bleeding to stem the blood flow.</p>
            <br />
            <p><b>Step 12 |</b>When the bleeding begings to slow, apply the plaster to the puncture.</p>
            <br />
            <p><b>Step 13 |</b>When the sample is dry, fold the cover over the sample and tuck into the bottom flap. Place one QR code from your activation card on the outside of the blood spot card. Place the other on your completed submission card.</p>
            <br />
            <p><b>Step 14 |</b>Place your completed dried bloods pot collection card, and completed submission card, with the QR codes securely affixed, into the return envelope. Close the envelope carefully and immediately post it back to our lab.</p>
            <br />
            <p><b>Step 15 |</b>Place the used equipment parts in the provided clear plastic bags. Dispose of them in your household waste.
            <br />
            <small>NB: The test's packaging and paper information inserts are fully recyclable.</small></p>
            <br /><br />
                    <div className="hair-page__hair-instruction">
                        <NavLink to="/hair" className="instructions-hair-test-link">
                            <p>Activate Blood Test</p>
                        </NavLink>
                    </div>
                </div>

            </section>
            </section>
    );
};

export default BloodInstructionsPage;