/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * ThankYou Page Component
 * Renders the thank you page when form submissions are complete
 * This occurs when the blood form completes its submission
 * 
 * @method openInNewTab : (url : string) => void
 * @returns ThankYouPage : PureComponent
 */

import './HairThankYouPage.scss';
import './BloodInstructionsPage.scss';
import { AiOutlineCloudDownload } from "react-icons/ai";
import takeBlueLogo from "../assets/take-blue.png";
import storeBlueLogo from "../assets/store-blue.png";
import printBlueLogo from "../assets/print-blue.png";
import resultsBlueLogo from "../assets/results-blue.png";
import { Link, useParams } from 'react-router-dom';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BsArrowLeftSquareFill } from "react-icons/bs";

// const localEndpoint = "http://localhost:80/activatemytest/api/requests/GenerateThankYouPDF.php";
// const liveEndpoint = "https://activatemytest.com/api/requests/GenerateThankYouPDF.php";

const HairThankYouPage = () => {

    // Set branding details                         
    const [brandName, setBrandName] = useState<string>("");
    const [brandColor, setBrandColor] = useState<string>("");
    const [brandLogo, setBrandLogo] = useState<string>("");
    const [brandEmail, setBrandEmail] = useState<string>("");
    const [brandContactNumber, setBrandContactNumber] = useState<string>("");
    const [brandContactURL, setBrandContactURL] = useState<string>("");
    const [brandAddress, setBrandAddress] = useState<string>("");
    const [brandAddress1, setBrandAddress1] = useState<string>("");
    const [brandAddress2, setBrandAddress2] = useState<string>("");
    const [brandAddress3, setBrandAddress3] = useState<string>("");
    const [brandAddress4, setBrandAddress4] = useState<string>("");

    // Set the template branding
    const setBrandTemplate = (code: Array<string>) => {

        // brand string
        let brand;

        // Set the brand code
        if (code.length === 2) { brand = code[0]; } else if (code.length === 3) { brand = `${code[0]}-${code[1]}`; }

        switch (brand) {

            case "GTL":
                setBrandColor("#29bfb6");
                setBrandLogo("../fpdf/assets/globaltestinglab/gtl_logo.png");
                setBrandEmail("info@globaltestinglab.com");
                setBrandContactNumber("");
                setBrandContactURL("https://www.globaltestinglab.com/gb/contact-us/");
                setBrandName("Global Testing Lab");
                break;

            case "TYI":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.testyourintolerance.com/contact-us/");
                setBrandName("Test Your Intolerance");
                setBrandAddress("1A Bonington Complex,")
                setBrandAddress1("Trent Lane Industrial Estate,");
                setBrandAddress2("Derby,");
                setBrandAddress3("DE74 2PY,");
                setBrandAddress4("United Kingdom.");
                break;

            case "SC":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.sensitivitycheck.com/contact");
                setBrandName("Sensitivity Check");
                setBrandAddress("1A Bonington Complex,")
                setBrandAddress1("Trent Lane Industrial Estate,");
                setBrandAddress2("Derby,");
                setBrandAddress3("DE74 2PY,");
                setBrandAddress4("United Kingdom.");
                break;

            case "SC-US":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.sensitivitycheck.co/contact/");
                setBrandName("Sensitivity Check");
                setBrandAddress("870 N. Miramar Avenue,");
                setBrandAddress1("32903,");
                setBrandAddress2("Indialantic, Florida,");
                setBrandAddress3("United States.");
                break;

            case "SC-CA":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.sensitivitycheckcanada.com/contact/");
                setBrandName("Sensitivity Check");
                setBrandAddress("Unit 106,");
                setBrandAddress1("482 South Service Road East,");
                setBrandAddress2("Oakville, ON,");
                setBrandAddress3("L6J 2X6,");
                setBrandAddress4("Canada.");
                break;

            case "SC-AUS":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.sensitivitycheckaustralia.com/contact/");
                setBrandName("Sensitivity Check");
                setBrandAddress("PO Box 491,");
                setBrandAddress1("Kellyville,");
                setBrandAddress2("NSW 2155,");
                setBrandAddress3("Australia.");
                break;

            case "SC-IE":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.sensitivitycheckireland.com/contact/");
                setBrandName("Sensitivity Check");
                setBrandAddress("Bracken Road No 51, Carlisle Offices,");
                setBrandAddress1("D18CV48,");
                setBrandAddress2("Sandyford,");
                setBrandAddress3("Dublin,");
                setBrandAddress4("Ireland.");
                break;

            case "TMP-UK":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.testmypets.co.uk/contact");
                setBrandName("Test My Pets");
                setBrandAddress("1A Bonington Complex,")
                setBrandAddress1("Trent Lane Industrial Estate,");
                setBrandAddress2("Derby,");
                setBrandAddress3("DE74 2PY,");
                setBrandAddress4("United Kingdom.");
                break;

            case "TMP-US":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.testmypets.com/contact/");
                setBrandName("Pet Health At Home");
                setBrandAddress("870 N. Miramar Avenue,");
                setBrandAddress1("32903,");
                setBrandAddress2("Indialantic, Florida,");
                setBrandAddress3("United States.");
                break;

            case "TMP-CA":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.testmypetscanada.com/contact/");
                setBrandName("Pet Health At Home");
                setBrandAddress("Unit 106,");
                setBrandAddress1("482 South Service Road East,");
                setBrandAddress2("Oakville, ON,");
                setBrandAddress3("L6J 2X6,");
                setBrandAddress4("Canada.");
                break;

            case "TMP-IE":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.testmypetsireland.com/contact/");
                setBrandName("Test My Pets");
                setBrandAddress("Bracken Road No 51, Carlisle Offices,");
                setBrandAddress1("D18CV48,");
                setBrandAddress2("Sandyford,");
                setBrandAddress3("Dublin,");
                setBrandAddress4("Ireland.");
                break;
                
            case "SC-NZ":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.sensitivitychecknewzealand.com/contact/");
                setBrandName("Sensitivity Check");
                setBrandAddress("Suite 8091,");
                setBrandAddress1("PO Box 13240 Johnsonville,");
                setBrandAddress2("Wellington 6440,");
                setBrandAddress3("New Zealand.");
                break;

            case "GTL-AU":
                setBrandColor("#29bfb6");
                setBrandLogo("../fpdf/assets/globaltestinglab/gtl_logo.png");
                setBrandEmail("info@globaltestinglab.com");
                setBrandContactNumber("");
                setBrandContactURL("https://www.globaltestinglab.com/au/");
                setBrandName("Global Testing Lab");
                break;

            case "GTL-CA":
                setBrandColor("#29bfb6");
                setBrandLogo("../fpdf/assets/globaltestinglab/gtl_logo.png");
                setBrandEmail("info@globaltestinglab.com");
                setBrandContactNumber("");
                setBrandContactURL("https://www.globaltestinglab.com/ca/");
                setBrandName("Global Testing Lab");
                break;

            case "GTL-US":
                setBrandColor("#29bfb6");
                setBrandLogo("../fpdf/assets/globaltestinglab/gtl_logo.png");
                setBrandEmail("info@globaltestinglab.com");
                setBrandContactNumber("");
                setBrandContactURL("https://www.globaltestinglab.com/us/contact-us/");
                setBrandName("Global Testing Lab");
                break;

            case "GTL-IE":
                setBrandColor("#29bfb6");
                setBrandLogo("../fpdf/assets/globaltestinglab/gtl_logo.png");
                setBrandEmail("info@globaltestinglab.com");
                setBrandContactNumber("");
                setBrandContactURL("https://www.globaltestinglab.com/ie/");
                setBrandName("Global Testing Lab");
                break;

            case "HS":
                setBrandColor("#578f57");
                setBrandLogo("../fpdf/assets/healthystuff/hs_logo.png");
                setBrandEmail("info@healthystuff.com");
                setBrandContactNumber("01332 812851");
                setBrandContactURL("");
                setBrandName("Healthy Stuff");
                break;

            case "AT":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/allergytest/at_logo.png");
                setBrandContactNumber("1 832 369-0544");
                setBrandContactURL("https://www.allergytest.co/contact-us/");
                setBrandName("Allergy Test");
                setBrandAddress("870 N. Miramar Avenue,");
                setBrandAddress1("32903,");
                setBrandAddress2("Indialantic, Florida,");
                setBrandAddress3("United States.");
                break;

            case "AT-AU":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/allergytest/at_logo.png");
                setBrandContactNumber("61 1800 196 618");
                setBrandContactURL("https://www.allergytestaustralia.com/contact-us/");
                setBrandName("Allergy Test");
                setBrandAddress("PO Box 491,");
                setBrandAddress1("Kellyville,");
                setBrandAddress2("NSW 2155,");
                setBrandAddress3("Australia.");
                break;

            case "RTYI":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolarance/tyi_logo.png");
                setBrandEmail("info@testyourintolerance.com");
                setBrandContactNumber("1 832 369-0544");
                setBrandContactURL("https://www.testyourintolerance.ca/contact-us/");
                setBrandName("Test Your Intolerance");
                break;

            case "AT-IE":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/allergytest/at_logo.png");
                setBrandContactNumber("1800 816 057");
                setBrandContactURL("https://www.allergytestireland.com/contact-us/");
                setBrandName("Allergy Test");
                setBrandAddress("Bracken Road No 51, Carlisle Offices,");
                setBrandAddress1("D18CV48,");
                setBrandAddress2("Sandyford,");
                setBrandAddress3("Dublin,");
                setBrandAddress4("Ireland.");
                break;

            case "AT-SG":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/allergytest/at_logo.png");
                setBrandEmail("info@allergytest.co");
                setBrandContactNumber("");
                setBrandContactURL("https://www.allergytestsingapore.com/contact/");
                setBrandName("Allergy Test");
                break;

            case "TYI-NZ":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolarance/tyi_logo.png");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.testyourintolerance.co.nz/contact-us/");
                setBrandName("Test Your Intolerance");
                setBrandAddress("Suite 8091,");
                setBrandAddress1("PO Box 13240 Johnsonville,");
                setBrandAddress2("Wellington 6440,");
                setBrandAddress3("New Zealand.");
                break;

            case "TYI-CA":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolarance/tyi_logo.png");
                setBrandContactURL("https://www.testyourintolerance.ca/contact-us/");
                setBrandName("Test Your Intolerance");
                setBrandAddress("Unit 106,");
                setBrandAddress1("482 South Service Road East,");
                setBrandAddress2("Oakville, ON,");
                setBrandAddress3("L6J 2X6,");
                setBrandAddress4("Canada.");
                break;

            case "TMFS":
                setBrandColor("#ea1b62");
                setBrandLogo("../fpdf/assets/tmi/tmi_logo.png");
                setBrandContactURL("https://testmyfoodsensitivity.com/faqs/");
                setBrandName("Test My Food Sensitivity");
                setBrandAddress("870 N. Miramar Avenue,");
                setBrandAddress1("32903,");
                setBrandAddress2("Indialantic, Florida,");
                setBrandAddress3("United States.");
                break;

            case "TMIT":
                setBrandColor("#ea1b62");
                setBrandLogo("../fpdf/assets/tmi/tmi_logo.png");
                setBrandEmail("info@tmitesting.com");
                setBrandContactNumber("01332 973877");
                setBrandContactURL("https://www.tmitesting.com/contact-page/");
                setBrandName("Test My Intolerance");
                break;

            case "TMA":
                setBrandColor("#ea1b62");
                setBrandLogo("../fpdf/assets/tma/tma_logo.png");
                setBrandEmail("info@testmyallergy.com");
                setBrandContactNumber("");
                setBrandContactURL("https://www.testmyallergy.com/contact-page/");
                setBrandName("Test My Allergy");
                break;

            case "TMI-AU":
                setBrandColor("#ea1b62");
                setBrandLogo("../fpdf/assets/tmi/tmi_logo.png");
                setBrandEmail("info@tmitesting.com");
                setBrandContactNumber("");
                setBrandContactURL("https://testmyintoleranceau.com/");
                setBrandName("Test My Intolerance");
                break;

            case "TYI-DE":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolarance/tyi_logo.png");
                setBrandEmail("info@testyourintolerance.com");
                setBrandContactNumber("");
                setBrandContactURL("https://www.testyourintolerance.de/");
                setBrandName("Test Your Intolerance");
                break;

            case "AT-ZA":
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/allergytest/at_logo.png");
                setBrandEmail("info@allergytest.co");
                setBrandContactNumber("");
                setBrandContactURL("https://www.allergytests.co.za/");
                setBrandName("Allergy Test");
                break;

            case "WL":
                setBrandColor("#000000");
                setBrandLogo("");
                setBrandEmail("");
                setBrandContactNumber("");
                setBrandContactNumber("");
                setBrandName("");
                break;

            case "GSPART":
                setBrandColor("#ea1b62");
                setBrandLogo("../fpdf/assets/globalscience/gs_logo.png");
                setBrandEmail("info@globalsciencetechnology.com");
                setBrandContactNumber("01332 321896");
                setBrandContactURL("");
                setBrandName("Global Science");
                break;

            default:
                setBrandColor("#0c7088");
                setBrandLogo("../fpdf/assets/testyourintolerance/tyi_logo.png");
                setBrandEmail("info@shealthystuff.com");
                setBrandContactNumber("01332 850085");
                setBrandContactURL("https://www.healthystuff.com/contact");
                setBrandName("Healthy Stuff");
                setBrandAddress("1A Bonington Complex,")
                setBrandAddress1("Trent Lane Industrial Estate,");
                setBrandAddress2("Derby,");
                setBrandAddress3("DE74 2PY,");
                setBrandAddress4("United Kingdom.");
                break;

        }

        console.log(brand);
    };


    // Open FPDF in a new page so that it automatically triggers the download by rendering the raw PHP file
    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noreferrer');
    };

    // Get parameters from the dynamic URL extracted from the redirect after form submission
    const params = useParams();
    const code = params.code;
    const name = params.name;
    const nameSplit = name!.split("-");
    const codeSplit = code!.split("-");

    console.log(code, nameSplit[0], nameSplit[1]);

    useEffect(() => {

        setBrandTemplate(codeSplit);

    }, [codeSplit]);

    // const local = `http://localhost:80/activatemytest/api/requests/GenerateThankYouPDF.php?code=${code}&firstName=${nameSplit[0]}&lastName=${nameSplit[1]}`;

    return (
        <section className="thank-you">

            <NavLink to="/hair-submissions" className="blood-form-link" style={{ fontSize: '20px' }}>
                <BsArrowLeftSquareFill />Submit Another Sample
            </NavLink>

            <div className="thank-you__title">
                <h3>Thank you for completing your submission form</h3>
                <p>Please follow the steps below.</p>
            </div>

            <div className="thank-you__subtitle">
                <h3>ABOUT YOUR {brandName.toUpperCase()} TEST – PLEASE READ CAREFULLY:</h3>
                <p>The following four steps are on the form for you to print and submit with your sample. Please read each step carefully. When you’re ready to take your sample, please ensure you have the following things ready: your sample collection kit. scissors, and a pen.</p>
            </div>

            <ul className="thank-you__steps">

                <li className="thank-you__step">

                    <img
                        src={takeBlueLogo}
                        alt="take your test blue logo"
                        width="60"
                        height="60"
                    />

                    <div>
                        <h3>Step 1 | Taking Your Sample</h3>
                        <p>Using your forefinger and thumb gather 3 or 4 hairs and slightly twist the hair together. This will make cutting the hair sample much easier. Try to cut the hair sample as close to the scalp as possible, using one cut. We do not need the follicle (root) for the testing, but this can be included if easier.</p>
                        <br />
                        <p><b>Guidance</b> | The sample does not need to be from your head; we can test any body hair. Hair colouring, bleaching, or other alternations and treatments will not affect the bio-resonance testing process.</p>
                    </div>
                </li>

                <li className="thank-you__step">
                    <img
                        src={storeBlueLogo}
                        alt="store your test blue logo"
                        width="60"
                        height="60"
                    />
                    <div>
                        <h3>Step 2 | Storing Your Sample</h3>
                        <p>Please seal the hair sample in the clear bag provided to ensure it stays dry and secure during transit. Ensure your sample is secure in the bag provided and your sample identifier number has been attached to the bag.</p>
                        <br />
                        <p><b>Guidance</b> | We cannot test samples that are loose, or in metal foil or metallic packaging. Please be careful not to include other items in the same bag as your sample as this can affect testing. Each person submitting samples will have a code, so ensure that each person has their code on their sample.</p>
                    </div>
                </li>

                <li className="thank-you__step">
                    <img
                        src={printBlueLogo}
                        alt="print your test blue logo"
                        width="60"
                        height="60"
                    />
                    <div>
                        <h3>Step 3 | Sending Your Sample</h3>
                        <p>Please fill in your Submission Card clearly labeling your Order Number, Order Date, Order Name, Name of Hair Sample, email address and your Contact Telephone number.</p>
                        <br />
                        <p><b>Guidance</b> | Place your hair sample and submission card in the secure envolope and mail this back to our lab. Please ensure the correct postage is paid; incorrect postage can cause major delays to the processing. <b>{brandName}</b> are not liable to pay additional fees, your sample may be returned.</p>
                        <br />
                        <p>Please send your sample to the address below:</p>
                        <br />
                        <p><b>{brandName}</b></p>
                        <p><b>{brandAddress}</b></p>
                        <p><b>{brandAddress1}</b></p>
                        <p><b>{brandAddress2}</b></p>
                        <p><b>{brandAddress3}</b></p>
                        <p><b>{brandAddress4}</b></p>
                    </div>
                </li>

                <li className="thank-you__step">
                    <img
                        src={resultsBlueLogo}
                        alt="get your results blue logo"
                        width="60"
                        height="60"
                    />
                    <div>
                        <h3>Step 4 | What happens next?</h3>
                        <p>Once you have mailed your sample, it willl be be delivered to the local postal hub in your country before being priority shipped to our testing facility in the UK.
                            Please note, while most samples arrive with our UK lab within 8 business days or less, during times of significant postal delays, this may take up to 21 business days. However, this is very rare.
                            When your sample arrives at our lab, you will receive an email confirming receipt. Our lab technician team will then process your sample, and your test results will be sent via email, to the email address you provided when activating your test.</p>
                        <br />
                        <p><b>Guidance</b> | It is important to note that bioresonance tests do not make a medical diagnosis nor are they intended to be a substitute for professional medical advice or treatment. If you are suffering from or suspect you have a medical condition you should consult a qualified practitioner in the first instance.</p>
                    </div>
                </li>
            </ul>

        </section>
    );
};

export default HairThankYouPage;